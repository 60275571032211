<template>
	<div>
		<qy-top-nav title="客户管理" />

		<div :style="{maxHeight: containerHeight,overflow:'auto'}">

			<div v-for="item in dataList" style="color:#333;background-color: #fff;margin: 10px;padding:12px 15px;">
				<div style="font-size: 14px;color: #333;line-height: 24px;position: relative;">
					<div>姓名：{{item.userName}}</div>
					<div>电话：{{item.phonenumber}}</div>
					<!-- <div style="position: absolute; transform: rotate(45deg); font-size: 12px; color: red; right: -11px; top: 0;  padding: 5px 8px 5px 8px; line-height: 12px;">
					<van-tag :type="item.tag=='A'?'danger':'primary'">{{getDict(tagList,item.tag)}}</van-tag></div> -->
				</div>
				<div style="display: flex;justify-content: flex-end;border-top: 1px solid #ededed;margin-top: 10px;padding-top: 15px;">
					<van-button type="warning" icon="edit"  round plain size="small" @click="navigateTo('/proxyClerkEdit',{userId:item.userId})">编辑</van-button>
				</div>
			</div>
		</div>
		<van-list v-model="loading" :finished="finished" :offset="offset" finished-text="没有更多了" @load="onLoad">
		</van-list>
		<div style="position: fixed;bottom: 1px;z-index: 10;width: 100%;padding: 0 10px;background-color: #fff;">
				<van-button type="danger" style="width: calc(100% - 20px);" @click="navigateTo('/proxyClerkEdit')"> 新增业务员</van-button>
		</div>
	</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		name: "customerList",
		components: {},
		data() {
			return {
				loading: false,
				finished: false,
				offset: 50,
				containerHeight: 0,
				pageSize: 10,
				pageNum: 1,
				dataList: [],
				tagList:[],
				tempObj: {},
			}
		},
		methods: {

			onLoad() {
				//是否已经登录
				let isLogin = this.checkLogin();
				if (!isLogin) {
					return;
				}
				this.doRequestProxyStg();
			},
			doRequestProxyStg() {
				this.doPost("/api/proxy/oper/listClient",{
					pageNum:this.pageNum,
					pageSize:this.pageSize,
					o:1,
					orderByColumn:"u.create_time",
					isAsc:"desc"
				}, (rs) => {
					this.onLoadSuccess(rs);
				});
			},
			onLoadSuccess(rs) {
				this.loading=false;
				if(this.pageNum==1){
					this.tagList=rs.otherData;
				}
				this.dataList=this.dataList.concat(rs.rows);
				this.finished=this.pageNum*this.pageSize>=rs.total;
				this.pageNum++;
			}

		},
		mounted() {
			this.containerHeight = document.documentElement.clientHeight - 140 + "px";
		}


	}
</script>
<style type="text/css" scoped="">
	.listDetails {
		display: flex;
		padding-left: 10px;
		align-items: center;
		margin-top: 10px;
		background-color: #fff;
		padding: 14px 10px;
	}

	.listDetails img {
		width: 100%;
		height: 100%;
		border-radius: 5px;
	}

	.shopImg {
		width: 150px;
		margin: 0 10px;
	}

	.shopImg img {
		width: 140px;
		height: 140px;
		border-radius: 10px;

	}

	.van-grid-item {
		width: 33.33%;
		flex-basis: 2px !important;
	}

	.van-cell__right-icon {
		color: #ccc;
		width: 30px;
		height: 15px;
		float: right;
		text-align: right;
		margin-top: -5px;
	}

	.goodsTitle .p1 {
		font-weight: bold;
	}

	.goodsTitle .p2 {
		color: #9c9c9c;
		background: #fbfbfb;
		padding: 4px 2px;
		height: 2.2em;
		overflow: hidden;
	}

	.van-grid-item {
		margin-bottom: 5px !important;
	}

	.title {
		font-size: 16px;
		font-weight: normal;
		padding: 15px;
	}

	.dayHistory img {
		width: 100%;
	}

	.goodsInfo {
		margin-top: 10px;
		width: 100%;
	}

	.goodsInfo {
		color: #ff6034;
	}
</style>